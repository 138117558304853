<template>
  <div>
    <PageBar Image="img/essay_p_bn.jpg" :Breadcrumbs="Breadcrumbs"></PageBar>

    <div class="main essayContainer" v-if="Journal">
      <div class="mainSearch">
        <div class="mainSearchTab">
          │
          <a href="javascript:void(0);" id="mainSearch1" class="act">全文檢索</a>
          │
        </div>
        <div class="searchRow">
          <input type="text" v-model="Keyword" />
          <a href="javascript:void(0);" class="mainSearchBtn" @click="search">
            <img src="img/search.svg" alt="" />
          </a>
        </div>
      </div>
      <div class="filterCol essayPageLeft">
        <div class="filterType">
          <h3>來源資料</h3>

          <h4>
            {{ Journal.JournalFileTitle }}<br />
            {{ Journal.JournalInfo }}
            <span>{{ Journal.JournalPage }}</span>
          </h4>
          <div class="downloadBtn" v-if="Journal.JournalFileUrl">
            <a href="javascript:void(0);" @click="download(Journal)">
              <img src="img/download_cloud.svg" alt="" />
              全文下載
            </a>
          </div>
        </div>
      </div>
      <div class="coursesContainer">
        <div class="essayPageContent">
          <h3>
            {{ Journal.Title }}
            <span>{{ Journal.SubTitle }}</span>
          </h3>
          <div class="subLeft">
            <img src="img/essayCover.jpg" alt="" class="essayCover" v-if="!Journal.ImageUrl" />
            <img :src="Journal.ImageUrl | contentCDN" alt="" class="essayCover" v-else />
          </div>
          <div class="subRight">
            <div class="author">
              <ul>
                <li v-for="a in Authors" :key="a.AuthorID">
                  <a href="javascript:void(0);">{{ a.Name }}</a>
                </li>
              </ul>
            </div>
            <div class="paperFrom">
              {{ Journal.JournalFileTitle }} ； {{ Journal.JournalInfo }} ，
              {{ Journal.JournalPage }}
            </div>
            <div class="lang">
              {{ Journal.Lang }}
            </div>
            <div class="tags">
              <template v-if="Journal.Keyword">
                <a class="keyword" href="javascript:void(0);" v-for="k in Journal.Keyword.split(',')" :key="k">{{ k
                  }}</a>
              </template>
              <template v-if="Journal.EnglishKeyword">
                <a class="keyword" href="javascript:void(0);" v-for="k in Journal.EnglishKeyword.split(',')" :key="k">{{
                  k }}</a>
              </template>
            </div>
          </div>

          <div class="summary">
            <h5>摘要</h5>
            <p>{{ Journal.Description }}</p>

            <h5>Summary</h5>
            <p>{{ Journal.EnglishDescription }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="popUp downloadUsage hide">
      <div class="alertWindow">
        <div class="p_head">
          <p>下載用途</p>
        </div>
        <div class="p_content">
          <div class="formRow">
            <label for="learning">
              <input type="checkbox" id="learning" v-model="Usage.Uses" value="學術研究" />
              學術研究
            </label>
          </div>
          <div class="formRow">
            <label for="personal">
              <input type="checkbox" id="personal" v-model="Usage.Uses" value="個人工作所需" />
              個人工作所需
            </label>
          </div>
          <div class="formRow">
            <label for="">
              <input type="checkbox" v-model="Usage.ShowElse" />
              其他(請說明)
            </label>
            <input type="text" placeholder="other" v-if="Usage.ShowElse" v-model="Usage.Else" />
          </div>
        </div>
        <div class="p_foot">
          <a href="javascript:void(0)" @click="close" class="cancel_btn">取消</a>
          <a href="javascript:void(0)" @click="postDownload(Journal)">送出</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageBar from "@/components/PageBar.vue";
import moment from "moment";
import user from "../plugins/user";

export default {
  components: { PageBar },
  data() {
    return {
      Breadcrumbs: [],
      Journal: null,
      Authors: [],
      Keyword: "",
      Usage: {
        Uses: [],
        Else: "",
        ShowSubmit: false,
      },
    };
  },
  async mounted() {
    var result = await this.$api.getJournal(this.$route.params.id);
    this.Journal = result.Journal;
    this.Authors = result.Authors;
  },
  methods: {
    search() {
      if (this.Keyword) {
        this.$router.push("/journals?key=" + encodeURIComponent(this.Keyword));
      }
    },
    async download(j) {
      if (user.user) {
        await this.$api.downloadJournalLog(j.JournalID, {});
        window.open(window.filters.contentCDN(j.JournalFileUrl));
      } else {
        this.Usage = {
          Uses: [],
          Else: "",
          ShowElse: false,
        };
        $(".downloadUsage").removeClass("hide");
      }
    },
    async postDownload(j) {
      if (this.Usage.Uses.length == 0 && !this.Usage.Else) {
        return;
      }
      var usage = [... this.Usage.Uses];
      if (this.Usage.Else) {
        usage.push(this.Usage.Else);
      }
      await this.$api.downloadJournalLog(j.JournalID, {
        Usage: usage.join(","),
      });
      window.open(window.filters.contentCDN(j.JournalFileUrl));
      $(".downloadUsage").addClass("hide");
    },
    close() {
      $(".downloadUsage").addClass("hide");
    },
  },
};
</script>